import React from "react"

import { Layout } from "../components/Layout"
import { Title } from "../components/Title"

function Page404() {
  return (
    <Layout>
      <Title>
        <>404</>
        <>Oup‘s 🙈</>
      </Title>
    </Layout>
  )
}

export default Page404
